import React, { useMemo } from "react";
import { View, Text, StyleSheet, Dimensions,TouchableOpacity,FlatList  } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { PieChart } from "react-native-chart-kit";
import COLORS from "../../../assets/component/colors";

const CustomPieChart = ({ data, accessor, absolute ,nav,year}) => {
   // Order by asscesor value


   const filterarr = ['Salary','Rental','Vehicle','utilities','Lhdn','Perkeso','Kwsp']

   const sortedData = useMemo(() => {
      return data.sort((a, b) => b[accessor] - a[accessor]);
   }, data);

   const percentages = useMemo(() => {
      // Get the total value of all dataitems
      const grandTotal = data.reduce((acum, item) => acum + item[accessor], 0);

      return data.reduce((acum, item) => {
         const percentageOfDataItem = (item[accessor] * 100) / grandTotal;
         return {
            ...acum,
            [item.name]: percentageOfDataItem.toFixed(2),
         };
      }, {});
   }, [absolute, data, accessor]);



   const RenderItem = ({item,index}) => {

      return(
         <View style={styles.legendItem} key={index}>
               <View style={{width:80,flexDirection:'row'}}>
               <FontAwesome name="circle" size={24} color={item.color} />
               <Text style={styles.legendItemValue}>
                  {absolute
                     ? item[accessor]
                     : `${percentages[item.name]}%`}
               </Text>
               </View>
               <TouchableOpacity style={[{flexDirection:'row'}]} onPress={()=>nav.navigate('exspense_details', {year:year,type:item.name})}>
               <Text style={{color:COLORS.Link}}> {item.name} RM {(item.amount).toFixed(2)} </Text>
            </TouchableOpacity>
         </View>
      )
  }

   const Exspenses = (data) => {
      var Result = sortedData.filter(o1 => !filterarr.some(o => o1.name === o))
      var Title = 'Exspense'
      var TxtColor = '#ae290f'

      if(data == 'Recurring'){
         Result = sortedData.filter(o1 => filterarr.some(o => o1.name === o));
         Title = 'Monthly Exspense'
         TxtColor = '#0035b9'
      }

      return (
         <View style={styles.dimensionimg}>
            <Text style={[styles.legendtitle,{color:TxtColor}]}>{Title}</Text>
            <View style={styles.legend}>
               <FlatList
                     showsHorizontalScrollIndicator={false}
                     data={Result}
                     renderItem={RenderItem}
                     onEndReachedThreshold={0}
                />
            </View>
         </View>
      )
   }

   return (
      <View style={styles}>
         <PieChart
            data={data}
            width={Dimensions.get("window").width}
            height={220}
            chartConfig={{
               backgroundColor: "#ffffff",
               backgroundGradientFrom: "white",
               backgroundGradientTo: "white",
               decimalPlaces: 2, // optional, defaults to 2dp
               color: (opacity = 1) => `rgba(54,64,81, ${opacity})`,
            }}
            accessor={accessor}
            backgroundColor="transparent"
            hasLegend={false}
            paddingLeft={Dimensions.get("window").width / 4}
         />


            <View style={{flexWrap:'wrap',flexDirection:'row',flex:1}}>
                  {Exspenses('Recurring')}
                  {Exspenses()}
            </View>
      </View>
   );
};

const styles = StyleSheet.create({
   legend: {
      borderWidth:1,
      borderRadius:5,
      borderColor:'#eee',
      padding:5,
      marginTop:5,
      marginRight:10
   },
   legendItem: {
      flexDirection: "row",
   },
   legendItemValue: {
      marginHorizontal: 10,
   },
   legendtitle:{
      fontSize:16,
      fontWeight:'500',
      marginLeft:5,
   },
   dimensionimg:{
      flex: 1,
      minWidth:250,
      maxWidth:500,
      marginBottom:10
   }
});

export default CustomPieChart;