import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {Modal,Button,LocalCurreny} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import {AddSalary,UpdateSalary} from "../../../../assets/js/firebase/salary";
import COLUMNS from "./col";


export default function GenerateSalary(props) {

    const {add,data,clear} = props



    const [staff, setStaff] = useState({});
    const [sumTotal, setSumTotal] = useState(0);
    const [isLoading, setIsloading] = useState(false);

    const title = data && data.type == 'generate'?'Generate':'Update'
    const PaidStat = data && data.status == 'paid' ? true:false

    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        setStaff((prevState) => ({ ...prevState, [name]: Value }));
        setSumTotal(SUMGross({...staff, [name]: Value }))
    };



    useEffect(() => {
        if(data){
            setStaff(data)
            setSumTotal(SUMGross(data))
        }

    }, [data]);
    







     async function Validate(){

        if(sumTotal){
            setIsloading(true)
            const PrevStat = staff.status
            const Type = staff.type
            staff['total'] = sumTotal

            delete staff.type

            if(Type == 'update'){
                const result = await UpdateSalary(staff)
                if(result){
                    add(result,'active',true)
                }
            }else{
                const result = await AddSalary(staff)
                if(result){
                    add(result,PrevStat)
                }
            }
            setIsloading(false)
        }
    
    }





        
    function SUMGross(data){
        const SUMobj = ['basic','income_others']
        const Deduction = ['kwsp2','socso2','eis2','pcb','deduction_others']
        var SUM = 0
        SUMobj.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM += parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        Deduction.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM -= parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM -= parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        return SUM
    }

    const SumTxt = () => {
        return (
            <View style={{flex:1,flexDirection:'row',alignItems:'baseline'}}>
                <Text style={{fontSize:12}}>Nett Amount : </Text>
                <Text style={{fontSize:18,fontWeight:'600'}}>{LocalCurreny(sumTotal)}</Text>
            </View>
        )
    }


    const Footer = () => {
        return(
            <View style={styles.footer}>
                <SumTxt/>
                <ButSubmit/>
            </View>
        )
    }

    const ButSubmit = () => {
        if(staff.status == 'paid' && staff.type !== 'generate') return null
        return <Button title={title} icon={'md-add-circle'} onPress={() => Validate()} isloading={isLoading}/>
    }


if(!staff || !data) return null

    
        return (
            <Modal title={title+' Salary'} icon={'receipt'} visible={data?true:false} setVisible={()=>clear(null)}>
                <ScrollView >
                    <View style={styles.htxt}><Text style={styles.mthtxt}>{data.month}</Text></View>
                    <COLUMNS data={staff} change={onChangeHandler} paid={PaidStat}/>
                                
                    <Footer/>
                </ScrollView>
            </Modal>
        )

    




}

const styles = StyleSheet.create({
    footer:{
        flexDirection:'row',
        padding:10
    },
    htxt:{
        alignItems:'flex-end',
        alignContent:'flex-end'
    },
      mthtxt:{
        fontSize:18,
        color:COLORS.Primary,
        fontWeight:'700'
      },
  });
  