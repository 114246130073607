import React, { Component,useState } from "react";
import {View,TextInput,StyleSheet,TouchableOpacity, ActivityIndicator} from "react-native";
import Clipboard from '@react-native-clipboard/clipboard';
import COLORS from "./colors";
import Text from "./text";
import {Ionicons} from '@expo/vector-icons';


export default function Copy(props) {

    const {link,title} = props;
    
    const [coppied, setCoppied] = useState(false);

    function CopyData(){
      setCoppied(true)
      Clipboard.setString(link)
    }

    const Btn = () => {
      if(props.children){
        return (
          <View style={styles.btn}>
            {props.children}
            <Ionicons name='ios-copy' size={12} color={'#ccc'} style={{marginLeft:5}}/>
          </View>
        )
      }
      return (
          <View style={styles.btn}>
              <Ionicons name='ios-copy' size={14} color={COLORS.Link} style={{marginLeft:5}}/>
              <Text style={styles.link}>{title}</Text>
          </View>
      )
    }

    if(coppied){
        return (
        <View style={{flexDirection:'row',marginVertical:5}}>
          {props.children}
          <Ionicons name='checkmark' size={16} color={'#aaa'} />
          {title && <Text style={{color:'#aaa',marginLeft:3}}>Coppied</Text>}
        </View>
        )
    }


      return (
              <TouchableOpacity onPress={()=>CopyData()}>
                    <Btn/>
              </TouchableOpacity>
      )
    }



    const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
        justifyContent:'flex-end',
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
      btn:{
        flexDirection:'row',
        alignContent:'center',
    },
    
});

  