import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import moment from 'moment';
import Generate from './generate';
import {StatusBx,Header,RowVal} from './assets';
import {CheckBox} from "../../../assets/component";

export default function Table(props) {

  const {setList,list,loading,setPicked,picked} = props

  const [generate, setGenerate] = useState(null);




    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const EditBtn = (item) => {
        if(item.status !== 'pending') return <View style={{width:50}}/>
        return (
          <TouchableOpacity style={[styles.flexcenter,{width:50}]} onPress={() => setGenerate({...item, 'type': "update"})} >
              <Ionicons name="edit" size={20} color="#ccc" />
          </TouchableOpacity>
        )
    }

    const AddBtn = (item) => {
      if(item.status == 'active') return <View style={{width:50}}/>
      return (
        <TouchableOpacity style={[styles.flexcenter,{width:50}]} onPress={() => delVal(item)} >
          <Ionicons name="add" size={20} color="#ccc" />
        </TouchableOpacity>
      )
    }

    function delVal(item){
      setGenerate({
        ...item, 
        'type': "generate",
        'basic':0,
        'income_others':[],
        'deduction_others':[],
        'kwsp':0,
        'kwsp2':0,
        'socso':0,
        'socso2':0,
        'eis':0,
        'eis2':0,
        'pcb':0
      })
    }

    const Thickbox = (data) => {

      var containsId = picked.find(x => x.id === data.id);

      if(data.status !== 'pending') return <View style={{width:30}}/>
      return (
        <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
      )
    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }



    const RenderItem = ({item,index}) => {

      const Opacity = item.type == 'generate'?0.2:1
      const BgColor = index % 2 == 0  ? "#FFFFFF" : "#f2f2f2"
      const Type = item.type?item.type:'update'

      const Income = SUMObj(['basic','income_others'],item)
      const Deduction = SUMObj(['kwsp2','socso2','eis2','pcb','deduction_others'],item)
      const Total = item ? parseFloat(item.total).toFixed(2) : 0
      return(
        <TouchableOpacity onPress={() => setGenerate({...item,'type':Type})} key={item.id} style={[styles.table,{backgroundColor: BgColor,opacity:Opacity }]}>
                  {Thickbox(item)}
                  <View style={{flex:3}}>
                    <Text style={styles.item}>{item.name}</Text>
                    <Text style={styles.id}>#{item.staff}</Text>
                      {StatusBx(item.status)}
                  </View>
                      {RowVal(Income)}
                      {RowVal(Deduction)}
                      {RowVal(Total)}
                  <View style={{flexDirection:'row'}}>
                      {EditBtn(item)}
                      {AddBtn(item)}
                  </View>
        </TouchableOpacity>
      )
  }


  function SUMObj(obj,data){
    var SUM = 0
    obj.forEach((item) => {
        if(typeof data[item] === 'object' && data[item] !== null){
            data[item].forEach((item2) => {
                SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
            })
        }else{
            SUM += parseFloat(data[item])?parseFloat(data[item]):0
        }
    });
    return SUM.toFixed(2)
  }

  async function UpdateData(data,prevstat,update){
    if(!update){
      if(prevstat == 'active'){
        var i = list.findIndex(x => x.id == data.staff)
        list[i] = data;
      }else{
        list.unshift(data)
      }
    }else{
      i = list.findIndex(x => x.id == data.id);
      list[i] = data;
    }
    setList(list);
    setGenerate(null)
  }
  



    return(
      <View style={styles.container}>
          <Header/>
          {Loader(loading)}
          <Generate data={generate} clear={setGenerate} add={UpdateData} />
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            keyExtractor={(item, index) => index.toString()}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  id:{
    marginRight:5,
    color:'#aaa',
    fontSize:12
  },
  table:{
    padding:5,
    paddingVertical:15,
    flexDirection:'row'
  }, 
});
