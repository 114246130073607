import React, { Component } from "react";
import {View,TextInput,StyleSheet,TouchableOpacity, ActivityIndicator} from "react-native";
import COLORS from "./colors";
import Text from "./text";
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';


export default function ButtonComponent(props) {

    const {title,icon,color,isloading,Iconfont,disable,size} = props;
    
    
    const TxtColor = color?'#fff':'#004ed4'

    if(isloading){
      return(
        <View style={styles.container} {...props}>
          <View style={[styles.BtnLoading]}>
            <View><ActivityIndicator size={'small'}/></View>
          </View>
        </View>
      )
    }

    const Icon = () => {
      if(!icon) return null
      
      if(Iconfont == 'MaterialCommunityIcons'){
        return <MaterialCommunityIcons name={icon} size={20} color={TxtColor} style={{marginRight:title?5:0}}/>
      } 
      return <Ionicons name={icon} size={20} color={TxtColor} style={{marginRight:title?5:0}}/>
    }

    const Color = () => {
      if(disable) return '#eee';
      if(color) return color
      return '#fff'
    }

    const TextBtn = () => {
      const Size = size == 'small'?12:14
      return (
        <Text style={{color:TxtColor,fontSize:Size}}>{title}</Text>
      )
    }

    const Padding = size == 'small' ? 6 : 10


    const Inverse = !color && styles.btnAddInverse

    return (
        <View style={styles.container} {...props}>
            <TouchableOpacity {...props} style={[styles.btnAdd,Inverse,{backgroundColor:Color(),padding:Padding}]} disabled={disable} >
              <Icon/>
              <TextBtn/>
            </TouchableOpacity>
        </View>
    );

  }


    const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
        justifyContent:'flex-end',
        marginTop:5,
        height:41
      },
      btnAdd:{
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
      },
      btnAddInverse:{
        borderWidth:1,
        borderColor:'#b9c8e2'
      },
      BtnLoading:{
        width:100,
        padding:10,
        backgroundColor:'#eee',
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center'
      }
    
});

  