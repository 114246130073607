import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity} from 'react-native';
import Modal from "react-native-modal";
import {Ionicons,MaterialCommunityIcons } from '@expo/vector-icons';
import TextInput from "../../assets/component/textinput";
import { ScrollView } from 'react-native-gesture-handler';

export default function ModalComponent(props) {
    
    const {visible,setVisible,title,icon,footer,headColor,header,Iconfont,width,back} = props


    const Icon = (TxtColor) => {
        if(!icon) return null
        
        if(Iconfont == 'MaterialCommunityIcons'){
          return <MaterialCommunityIcons name={icon} size={24} color={TxtColor} style={{marginRight:5}}/>
        } 
        return <Ionicons name={icon} size={24} color={TxtColor} style={{marginRight:5}}/>
    }


    const Header = (title) => {

        const Bgcolor = headColor?headColor:null
        const TxtColor = headColor?'#fff':'#444'

        return (
            <View style={[styles.header,{backgroundColor:Bgcolor}]}>
                <View style={styles.center}>
                {Icon(TxtColor)}
                    <Text style={[styles.title,{color:TxtColor}]}>{title}</Text>
                    <TouchableOpacity onPress={() => setVisible(false)}>
                        <Ionicons name='close' size={22} color={TxtColor} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    const Footer = () => {
        if(!footer) return null
        return (
            <View style={{padding:10,borderTopWidth:1,borderColor:'#ddd'}}>
            {footer}
          </View>
        )
    }

    return(

      <Modal isVisible={visible} onBackdropPress={() => !back && setVisible(false)} style={{alignItems:'center'}}>
        <View style={[styles.container,{width:width?width:'100%'}]}>
          {header?header:Header(title)}
          <ScrollView style={styles.content}>
                {props.children}
          </ScrollView>
          {Footer()}
        </View>
      </Modal>

    )

}

const styles = StyleSheet.create({
    container:{
        backgroundColor:'#fff',
        borderRadius:10,
        maxHeight:'100%',
        maxWidth:600
    },
    content:{
        padding:10,
        flex:1
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#ccc',
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        flex:1
    },
  });
  