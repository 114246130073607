import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,Text, ActivityIndicator,TouchableOpacity} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import {GetAllDocs,GetItemDetails,GetDocsByids } from "../../../../../assets/js/firebase";
import moment from 'moment';
import {LocalCurreny} from "../../../../../assets/component";
import {YearSelector} from '../../../../../assets/component/momentselect';
import Tab from './tab';
import Table from './table';


export default function StaffSalaryScreen({route,navigation}) {
  const {pid} = route.params;

  
  const DafaultTab = {paid:[],pending:[]}

  const [year, setYear] = useState(moment().format("YYYY"));
  const [item, setItem] = useState(null);
  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);




  useEffect(() => {
    GetStaff()
   }, []);

  useEffect(() => {
    if(year){
      setIsloading(true)
      getSalary(year);
    }

  }, [year]);


  async function getSalary(year){
    const result = await GetAllDocs('staff/'+pid+'/salary/year/'+year)
    setList({'paid':Filter(result,'paid'),'pending':Filter(result,'pending')});
    setIsloading(false)
  };

  function Filter(arr,status){
    return arr.filter(obj => obj.status === status);
  }




  async function GetStaff() {
    const ProductsDetail = await GetItemDetails(pid,'staff')
    if(ProductsDetail){
      setItem(ProductsDetail)
    }else{
      navigation.navigate('staff')
    }
    
  }



  function StockNav(data){
    navigation.navigate('stock', {pid:data.id})
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD-MM-YYYY HH:MM A');
    return format
}

const BackBtn = () => {
  return (
    <View style={{flex:1}}>
          <TouchableOpacity style={styles.backbtn} onPress={()=>navigation.goBack()}>
            <Ionicons name={'chevron-back-outline'} size={28} color='#4a8eb5' />
            <Text>BACK</Text>
          </TouchableOpacity>
    </View>
  )
}



const Details = () => {
  if(!item) return null

  var SumVal = 0
  list[tabactive].map(val=>{
    if(val.income_others){
      const sum = val.income_others.reduce( ( sum , {amount} ) => sum + parseFloat(amount) , 0)
      SumVal = SumVal + sum
    }
  })

  const MapD = [
        {name:'Name',value:item['name']},
        {name:'MyId',value:item['mykad']},
        {title:'SUM YEAR '+year},
        {name:'Basic',value:SumTotal('basic')},
        {name:'Others Income',value:LocalCurreny(SumVal)},
        {name:'PCB',value:SumTotal('pcb')},
        {name:'Kwsp2',value:SumTotal('kwsp2'),name2:'Kwsp',value2:SumTotal('kwsp')},
        {name:'Perkeso2',value:SumTotal('socso2'),name2:'Perkeso',value2:SumTotal('socso')},
        {name:'Eis2',value:SumTotal('eis2'),name2:'Eis',value2:SumTotal('eis')},
      ]


  const SubItem = (v) => {
    if(!v.name2) return null
    return (
      <View style={styles.details}>
          <Text style={{width:70}}>{v.name2}</Text>
          <Text style={{paddingHorizontal:5}}>:</Text>
          <Text style={{flex:1,color:'#2172ff'}}>{v.value2}</Text>
      </View>
    )
  }
    var txtC = '#099488'
  return (
    <View style={{padding:5,marginVertical:10}}>

            {MapD.map((v,i)=> {
              if(v.title){
                txtC = '#c44444'
                return <View key={i} style={{paddingVertical:5}}><Text style={{fontWeight:'800',color:txtC}}>{v.title}</Text></View>
              }
                return (
                  <View key={i} style={{flexDirection:'row'}}>
                      <View style={styles.details}>
                        <Text style={{width:70}}>{v.name}</Text>
                        <Text style={{paddingHorizontal:5}}>:</Text>
                        <Text style={{flex:1,color:txtC}}>{v.value}</Text>
                      </View>
                      {SubItem(v)}
                  </View>
                )
              })}

    </View>
  )
}



function SumTotal(vari){
  const sum = list[tabactive].reduce( ( sum , cur ) => sum + parseFloat(cur[vari]) , 0)
  return LocalCurreny(sum?sum:0)
}






  return (
    <View style={styles.container}>
      <View style={{flexDirection:'row',zIndex:99}}>
        <BackBtn/>
        <YearSelector setYear={setYear} year={year}/>
      </View>
      <Details/>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} list={list}/>
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        staff={item}
        loading={isLoading}
        nav={StockNav}
      />


    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
  center:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    flex:1
  },
  backbtn:{
    borderRadius:5,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  details:{
    flexDirection:'row',
    width:200,
  }
});
