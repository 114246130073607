import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet ,View,ScrollView} from 'react-native';
import moment from 'moment';
import {GetAllDocs} from "../../../assets/js/firebase";
import {TextInput,Text,Button} from "../../../assets/component";



export default function TransferScreen({navigation}) {


  const [data, onData] = useState('');
  const [from, onFrom] = useState('orders');
  const [limit, onLimit] = useState('2');
  const [to, onTo] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {

  }, []);
  


 async function SearcCol(){
  const result = await GetAllDocs(from,null,limit)
  if(result){
    onData(result)
  }
 }

 const SearcBx = () => {
  return (
    <View>
      <Text style={{fontSize:16,fontWeight:'700'}}>Search Collection</Text>
      <Text style={{fontSize:12}}>Find Firestore collection before copy to other collection</Text>
      <View style={styles.searchbx}>
        <View style={{flexDirection:'row',flex:1}}>
          <View style={{flex:1}}><TextInput title={'from'} remark={'{name}/collection'} onChangeText={onFrom} value={from}/></View>
            <View style={{width:100}}><TextInput title={'limit'} remark={'max 5'} onChangeText={onLimit} value={limit}/></View>
        </View>
        <FoundBox/>
        <Button onPress={SearcCol} icon={'search'} title={'Search'}/>
      </View>
    </View>
  )
 }

 const FoundBox = () => {
  if(!data.length) return null

  return (
    <View style={styles.found}>
      <Text>Found {data.length}</Text>
      <ScrollView style={{height:180}}>
        {Obj(data)}
      </ScrollView>
    </View>
  )
 }

 const Obj = (arr) => {

  return arr.map((data,i)=> {
    const Year = moment(data.date,'DD-MM-YYYY').format('YYYY')
    const Month = moment(data.date, 'DD-MM-YYYY').format('MM')
    return <View key={i}><View style={styles.bx}><Text>{Year + '/'+data.id}</Text></View><Text>{JSON.stringify(data)}</Text></View>
  })
 }





  return (
    <View style={styles.container}>
      <View style={styles.formC}>
        {SearcBx()}
        <View style={styles.searchbx}>
            <TextInput title={'to'} remark={'{name}/collection'} onChangeText={onTo} value={to}/>
            <Button onPress={SearcCol} icon={'copy'} title={'copy'}/>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  formC:{
    margin:10,
    flex:1,
    maxWidth:500,
  },
  searchbx:{
    padding:5
  },
  found:{
    borderWidth:1,
    borderRadius:5,
    padding:5,
    borderColor:'#b7e1e2',
    backgroundColor:'#f3ffff',
    marginBottom:5
  },
  bx:{
    borderWidth:0.5,
    borderRadius:3,
    padding:2,
    backgroundColor:'#eee'
  }
});
