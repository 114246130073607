import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import {Button,Form} from '../../../assets/component';
import {SearchOrder} from '../../../assets/js/firebase/order';
import ListDownOverlay from '../../../assets/component/listdownoverlay';




export default function NotFound(props) {

    const {set,text} = props

    const [values, setValues] = useState(null);
    const [isLoading, SetIsLoading] = useState(false);



    useEffect(() => {
      if(text){
        setValues({...values,'name':text,'by':'name'})
      }
    }, [text]);
    



    async function Search(){
      const result = await SearchOrder(values)
      if(result.length){
        set(result)
      }else{
        set({error:'Not Found',search:text})
      }
    }

    const Mobile = values && values.by == 'mobile'?true:false


    return(
      <View style={styles.container}>
        <View style={{flexDirection:'row'}}>
            <Text style={styles.txt}>Not Found </Text>
            <Text style={[styles.txt,{fontWeight:'700'}]}>{text}</Text>
            <Text style={styles.txt}> Click here To Search </Text>
        </View>
        <View style={{width:'80%'}}>
              <Form 
                data={
                      [
                        [{name:'name',flex:1,mobileno:Mobile},{name:'by',listdownoverlay:['name','id','company','mobile']},{name:'search',color:'#dc3545',icon:'search',button:true,'onPress':Search,isLoading:isLoading}],
                      ]}
                value={values}
                set={setValues}
              />
        </View>

      </View>
    )


}

const styles = StyleSheet.create({
  container:{
    padding:10,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    zIndex:22,
  },
  txt:{
    color:'#aaa'
  }
});
