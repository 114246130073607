import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Image} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';


export default function Table(props) {

  const {tab,setList,list,loading,nav} = props


  const [sortasc, setSortAsc] = useState(true);



  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          <View style={{width:50}}/>
          {Th('Name',2)}
          {Th('Position',1)}
          {Th('Created',2,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const UserIcon = (img) => {
      if(!img) return  <View style={styles.user}><Ionicons name="person" size={20} color="#ccc" /></View>
      return (
           <View style={styles.photoc}>
                <Image style={styles.photobx} source={{ uri: img}}/>
            </View>
      )
    }



    const RenderItem = ({item,index}) => {


      return(
        <TouchableOpacity  onPress={()=>nav('Staff_portal',item.id)} style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row'}}>
                  <View style={{width:50}}>{UserIcon(item.image)}</View>
                  <View style={{flex:2}}>
                       <Text  style={[styles.title]}>{item.name}</Text>
                       <Text style={[styles.item]}>{item.mykad}</Text>
                       <Text style={[styles.item]}>staff : {item.id}</Text>
                  </View>
                  <View style={{flex:1}}><Text style={[styles.item]}>{item.position}</Text></View>
                  <View style={{flex:2}}><Text style={[styles.qty]}>{ReformatTime(item.createdAt)}</Text></View>
              </View>
        </TouchableOpacity>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:MM A');
    return format
  }
  



    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#4a8eb5',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    color:'#aaa',
    fontSize:12
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  user:{
    borderWidth:1,
    padding:5,
    borderRadius:5,
    borderColor:'#ccc',
    width:30,
    alignContent:'center',
    alignItems:'center'
  },
  photoc:{
    alignContent:'center',
    alignItems:'center',
    marginRight:10,
  },
  photobx:{
    width:50,
    height:50,
    backgroundColor:'#eee',
  },
});
