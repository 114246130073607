import React, { useState,useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {Form} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";


export default function StaffForm(props) {

    const {data,set} = props

       const Amount = data.type == 'service' ? [{name:'amount',display:'Amount'}]: []

        return (
            <Form 
                data={
                            [
                                [{name:'image',display:'Image',image:true,format:'image/*',size:65},{name:'name',display:'Product Name'}],
                                [{name:'desc',display:'Description'},{name:'variant',display:'Variant',remark:'Color code'}],
                                ['Measurement'],
                                [{name:'product_measurement',display:'Product Measurement',remark:'100x100x500MM'},{name:'packaging_measurement',display:'Packaging Measurement',remark:'100x100x500MM'}],
                                ['Weight'],
                                [{name:'weight',display:'Gross Weight'},{name:'net_weight',display:'Net Weight'}],
                                [...Amount],
                                [{name:'type',display:'Type',listdown:['stock','service']}],
                            ]
                    }
                value={data}
                set={set}
            />
        )


}

const styles = StyleSheet.create({
    container:{
        width:'100%',
        zIndex:33,
        marginBottom:100
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  