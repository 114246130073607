import {getFirestore,writeBatch,collection,doc,Timestamp,serverTimestamp,arrayUnion,increment} from 'firebase/firestore';
import {GETACC} from '../../getacc';
import {IncreaseID} from '../index'
import moment from 'moment';


async function Init() {
	const db = getFirestore();
	const user = await GETACC();
	const batch = writeBatch(db);
	return {
		db,
		user,
		batch
	}
}


export const AddSalary = async (data) => {

	const {db,user,batch} = await Init()

	data['createdAt'] = Timestamp.now()
	data['status'] = 'pending'


	const Year = moment(data.month, 'MM-YYYY').format('YYYY')
	const Month = moment(data.month, 'MM-YYYY').format('MM')

	const Ref = doc(collection(db, "account/" + user.id + "/salary/"+Year+"/" + Month ));
	batch.set(Ref, data);

	const route = 'staff/'+data.staff+'/salary/year/'+Year+'/'+Ref.id
	data['salary_id'] = Ref.id

	const nycRef = doc(db, "account/" + user.id + "/" + route);
	batch.set(nycRef, data)

	await batch.commit();

	data['createdAt'] = Timestamp.now()
	data['id'] = Ref.id


	return data


};


export const UpdateSalary = async (data) => {

	const {db,user,batch} = await Init()

	data['createdAt'] = Timestamp.now()
	data['status'] = 'pending'


	const Year = moment(data.month, 'MM-YYYY').format('YYYY')
	const Month = moment(data.month, 'MM-YYYY').format('MM')

	const route = 'staff/'+data.staff+'/salary/year/'+Year+'/'+data.id

	const nycRef = doc(db, "account/" + user.id + "/" + route);
	batch.update(nycRef, data)


	const Ref = doc(db, "account/" + user.id + "/salary/"+Year+"/" + Month + "/" + data.id);

	batch.update(Ref, data);

	await batch.commit();

	data['createdAt'] = Timestamp.now()

	return data


};


export const TransferData = async (item,year) => {

	const {db,user,batch} = await Init()

	for (const [key, value] of Object.entries(item)) {
		value.forEach(async data => {

			const route = "account/" + user.id + "/salary/"+year+"/" + key + "/" + data.id
		
			const nycRef = doc(db,route);
			batch.set(nycRef, data)

			console.log(route)

	
		});
	}


	await batch.commit();
	
	return item


};



export const UpdateSalaryStatus = async (item) => {

	const {db,user,batch} = await Init()


	const LengthStaff = item.length

	var CreditID = await IncreaseID(user.id, 'credits', 8000, LengthStaff);

	var StartID = CreditID - LengthStaff
	
	item.forEach(async element => {

		StartID++

		const Year = moment(element.month, 'MM-YYYY').format('YYYY')
		const Month = moment(element.month, 'MM-YYYY').format('MM')

		const refS = doc(db, "account/" + user.id + "/staff/" + element.staff + '/salary/year/'+Year+'/'+ element.id);

					batch.update(refS, {
						'status': 'paid',
						'updated': serverTimestamp()
					})


		var credit = {}
		credit['category'] = {	
								'account':element.account,
								'bank':element.bank,
								'customer_id':element.mykad,
								'category': "Salary",
								'name': 'Salary (' + element.name + ')',
								'sub_category': element.staff
							}
		credit['status'] = 'active'
		credit['amount'] = element.total
		credit['staff'] = element.staff
		credit['date'] = new Date();


		if (CreditID) {
			const DateFormat = moment(credit.date, 'DD-MM-YYYY')

			credit['status'] = 'active'
			credit['createdAt'] = serverTimestamp()
			credit['date'] = Timestamp.fromDate(new Date(DateFormat));


			const UpdateIncrese = {
				['total_credits']: increment(credit.amount),
				'last_updated_credits': serverTimestamp()
			}


			const Ref = doc(db, "account/" + user.id + "/acc/" + DateFormat.format('YYYY') + "/credits/" + StartID);
						batch.set(Ref, credit);

			const RefType = doc(db, "account/" + user.id + "/acc/" + DateFormat.format('YYYY') + "/");
							batch.set(RefType, UpdateIncrese, {merge: true});

		}

		const refC = doc(db, "account/" + user.id + "/salary/"+Year+"/" + Month + "/" + element.id);
					batch.update(refC, {
						'status': 'paid',
						'updated': serverTimestamp(),
						'paid_id': StartID
					})

	});

	await batch.commit();

	return item

};







