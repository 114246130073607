import {getFirestore,writeBatch,collection,doc,getDoc,orderBy,getDocs,where,query} from 'firebase/firestore';
import {GETACC} from '../../getacc';
import {IncreaseID} from '../index'
import moment from 'moment';


async function Init() {
	const db = getFirestore();
	const user = await GETACC();
	const batch = writeBatch(db);
	return {
		db,
		user,
		batch
	}
}


export const SearchOrder = async (values) => {

	const {
		db,
		user,
		batch
	} = await Init()
	const ref = "account/" + user.id + "/orders"

	if(values.by == 'id'){
		const docRef = doc(db, ref, values.name);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			var docData = docSnap.data();
			docData['id'] = docSnap.id
			return [docData]
		}
	}


	const q = query(collection(db, ref), where(values.by, "==", values.name) , orderBy("createdAt", "desc"));


	const documentSnapshots = await getDocs(q);



	var dataArray = []

	documentSnapshots.forEach(doc => {
		const data = doc.data()
		data['id'] = doc.id
		dataArray.push(data)
	});



	return dataArray

};










