import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Image,Linking} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {GetDocument } from "../../../../../assets/js/firebase";
import COLORS from "../../../../../assets/component/colors";


export default function Proof(props) {

    const {data} = props


          if(!data.proof) return null
          return (
            <View style={{flexDirection:'row'}}>
                <TouchableOpacity style={{flex:1}} onPress={()=>Linking.openURL(data.proof.image, '_blank')}>
                  <Image style={{width:150,height:120}} source={{uri: data.proof.image}} />
                </TouchableOpacity>
                <View style={{flex:1}}>
                  <Image style={{width:150,height:120}} source={{uri: data.proof.signed}} />
                </View>
            </View>
          )


}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
});
