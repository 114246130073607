import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {Modal,Button} from "../../../assets/component";
import COLORS from "../../../assets/component/colors";
import {AddStaff,UpdateData,updateImageLink,AddProduct} from "../../../assets/js/firebase";
import {UploadData} from "../../../assets/js/upload";
import Form from "./form";


export default function AddNew(props) {

    const {add,updateData,visible,set,update} = props


    const [isLoading, setIsloading] = useState(false);
    const [data, onData] = useState({});
    const [error, setError] = useState("");

    useEffect(() => {
        if(updateData){
            onData(updateData)
        }
    }, [updateData]);




    async function Validate(){
        setError('')
        const valid = Validation(['name','desc','type']);
        const validNum = data.type == 'service' ? ValidationNum(['amount']) : true
        if(valid && validNum){
            setIsloading(true)
            const file = data.file
            if(updateData){
                Update(data,file)
            }else{
                AddNew(data,file)
            }
        }else{
            setError("Fill All")
        }
    }

    async function Update(data,file) {
        const ID = data.id
        delete data.file
        const AddStatus = await UpdateData('products',ID,data);
        if(AddStatus){
            if(file){
                const img = await UploadImage(ID,file)
                data['image'] = img
            }
            setIsloading(false)
            data['id'] = ID
            update(data)
            onData({})
            set(null)
        }
    }

    async function AddNew(data,file) {
        delete data.file
        const AddStatus = await AddProduct(data);
            if(AddStatus){
                if(file && data.type =='stock'){
                    const img = await UploadImage(AddStatus.id,file)
                    AddStatus['image'] = img
                }
                setIsloading(false)
                add(AddStatus)
                onData({})
                set(false)
            }
    }

    async function UploadImage(id,file){
            const Route = 'products/'+id
            const UploadedURL  = await UploadData('photo',file,Route)
            await updateImageLink(Route,UploadedURL)
            return UploadedURL
    }
    

    function Validation(obj){
        var validated = true
        obj.map((name)=>{
            if(!data[name] || data[name].error){
                onData((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
                validated = false
            }
        })
        return validated
    }

    function ValidationNum(obj){
        var validated = true
        obj.map((name)=>{
            if(data[name] <= 0 || isNaN(data[name]) || data[name].error){
                onData((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
                validated = false
            }
        })
        return validated
    }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    const Footer = () => {
        return (
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}><Error error={error}/></View>
                {!isLoading?<Button title={updateData?'UPDATE':'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
            </View>
        )
    }

    if(updateData){
        return (
                <Modal title={'UPDATE STAFF'} icon={'person'} visible={true} setVisible={set} footer={Footer()}>
                    <Form data={data} set={onData}/>
                </Modal>
        )
    }

    return (
        <Modal title={updateData?'UPDATE PRODUCT': 'ADD PRODUCT'} icon={'person'} visible={visible} setVisible={set} footer={Footer()}>
            <Form data={data} set={onData}/>


        </Modal>
    )


}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      dualL:{
        flex:1,
        marginRight:5
      },
      dualR:{
        flex:1,
        marginLeft:5
      },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  