import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView, FlatList,Image, ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import {UpdateSalaryStatus} from "../../../../assets/js/firebase/salary";
import Modal from "../../../../assets/component/modal";
import Button from "../../../../assets/component/button";
import { TouchableOpacity } from 'react-native-gesture-handler';




export default function PayModal(props) {

    const {details,set} = props

    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        if(modalVisible){

        }

    }, [modalVisible]);

    const Footer = () => {
        if(isLoading) return <ActivityIndicator size={'small'}/>
        return (
            <Button title={'PAY'} icon={'checkmark-circle'} color={'#4a90ff'} onPress={() =>UpdatetoPaid(details)}/>
        )
    }



    const RenderItem = ({item,index}) => {
        return(
                <View style={{flexDirection:'row',flex:1}} key={index}>
                    <View style={{flex:1}}><Text style={styles.item}>{item.mykad}</Text></View>
                    <View style={{flex:1}}><Text style={styles.item}>{item.name}</Text></View>
                    <View style={{flex:1}}><Text style={styles.item}>{item.kwsp}</Text></View>
                    <View style={{flex:1}}><Text style={styles.item}>{item.kwsp2}</Text></View>
                </View>
        )
    }



     const StaffList = () => {
        const result = details.map(({kwsp,kwsp2,name,mykad,...rest}) => ({kwsp,kwsp2,name,mykad}));
        return (
                <FlatList
                    showsHorizontalScrollIndicator={false}
                    data={result}
                    renderItem={RenderItem}
                    onEndReachedThreshold={0}
                    keyExtractor={(item, index) => index.toString()}
                />
        )
     }


     async function UpdatetoPaid(data){
        setIsloading(true)
        const result = await UpdateSalaryStatus(data)
        if(result){
            set(data)
            setModalVisible(false)
        }
    }
    



    const Popup = () => {

        return (
                <Modal title={'Details'} icon={'md-list'} visible={modalVisible} setVisible={setModalVisible} footer={Footer()}>
                        <ScrollView>
                            <StaffList/>
                        </ScrollView>
                </Modal>
        )
    }

    const Btn = () => {

        if (details.some(e => e.status === 'paid')) return null
          
        return (
            <TouchableOpacity style={[styles.center,styles.btn]} onPress={() => setModalVisible(true)}>
                <Ionicons name={"ios-checkmark-circle"} size={20} color="#fff" />
                <Text style={{color:'#fff',marginLeft:5}}>PAY</Text>
            </TouchableOpacity>
        )
    }
    


    return(
        <View style={styles.container}>
            <Btn/>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        flexDirection:'row',
        marginRight:10
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
    },
    btn:{
        backgroundColor:'#4a90ff',
        borderRadius:5,
        padding:15,
        flex:1
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      item:{
        marginRight:5,
        color:'#444',
      },
      ProductImg: {
        width: 50,
        height: 50,
      },
      small:{
        width: 25,
        height: 25,
        marginRight:4
      }
  });
  