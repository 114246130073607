import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View} from 'react-native';
import moment from 'moment';
import {GetAllDocs} from "../../../assets/js/firebase";
import {MonthSelector} from '../../../assets/component/momentselect';
import {SearchBox,Text} from "../../../assets/component";
import Table from './table';
import Footer from './footer';



export default function PayrollScreen({navigation}) {


  const [month, setMonth] = useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [picked, setPicked] = useState([]);



  useEffect(() => {
      if(month){
          setIsloading(true)
          getList(month);
          setPicked([])
      }
  }, [,month]);
  
  


  async function getList(mth){
      const Staff = await GetAllDocs('staff','active')
      const dt = GetMthYear(mth)
      const ListProduct = await GetAllDocs('salary/'+dt.year+'/'+dt.month)

      const ids = new Set(ListProduct.map(e => e.staff));

      Staff.forEach(e => {
          if (!ids.has(e.id)) {
            ListProduct.push({'type':'generate','staff':e.id,'month':mth,...e});
          }
      });
      setList(ListProduct);
      setIsloading(false)
  };

  function GetMthYear(data){
    const Year = moment(data, 'MM-YYYY').format('YYYY')
    const Month = moment(data, 'MM-YYYY').format('MM')
    return {'month':Month,'year':Year}
  }




  function MonthSelect(mth){
    setPicked([])
    setMonth(mth)
  }

  const SearchBx = () => {
    return (
      <View style={{flexDirection:'row',zIndex:33}}>
          <View style={{flex:3}}><SearchBox set={(val)=>setList(val)} products={list} filters={['name', 'staff']}/></View>
          <MonthSelector set={MonthSelect} value={month}/>
      </View>
    )
  }

  function UpdatePaid(staff){
    const New = list.map(function(x){ 
      var result=staff.filter(a1=> a1.id==x.id);
      if(result.length>0) { x.status='paid';}
      return x 
    })
      setList(New)
      setPicked([])
  }





  return (
    <View style={styles.container}>
      <View style={{padding:10,flex:1,zIndex:22}}>
      <SearchBx/>
      <Table 
        setList={setList} 
        list={list} 
        loading={isLoading}
        setPicked={setPicked}
        picked={picked}
      />
      </View>
      <Footer month={month} picked={picked} set={setList} list={list} update={UpdatePaid}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#444',
  },
});
