import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator} from 'react-native';
import moment from 'moment';
import {FormatDate} from "../../../../assets/js/format";
import {Ionicons} from '@expo/vector-icons';



export const CreditRow = (item,nav,selected) => {
  const {category,name,date} = item
  const val = category?category.name:name
  return (
    <View style={{flex:1,marginBottom:4}}>
      <Text style={styles.title}>{val}</Text>
      <View style={styles.flexcenter}>
        {Link(selected,nav,date,category)}
        <Ionicons name="chevron-forward" size={10} style={{marginTop:4}} color="#ccc" />
        {Link(selected,nav,date,category,'sub')}
      </View>
    </View>
  )
}

const Link = (selected,nav,date,val,sub) => {
  const {category,sub_category} = val
  const Txtval = sub?sub_category:category 
  const link = sub?'exspense_details_sub':'exspense_details'
  var Arr = {year:FormatDate(date,'YYYY'),type:category,sub:sub && sub_category}
  return (
    <TouchableOpacity onPress={()=>nav.navigate(link, Arr)} disabled={!selected}>
      <Text style={styles.category}>{Txtval}</Text>
    </TouchableOpacity>
  )
}

export const DebitRow = (item) => {
  if(!item.name) return null
  return (
    <View>
      <Text style={[{color:!item.orderid && '#038399'}]}>{item.name}</Text>
      {item.orderid&&<Text style={styles.category}>Order id : {JSON.stringify(item.orderid)}</Text>}
      {item.shopee_orders&&<Text style={styles.category}>total orders : {item.shopee_orders.length}</Text>}
      <CreditNote data={item}/>
    </View>
  )
}

const CreditNote = (props) => {
  const {data}  = props
  if(data.payment_method !== 'credit note') return null
  return <Text style={{color:'#b895ea'}}>Credit Note</Text>
}


const styles = StyleSheet.create({
    container:{
        flex: 1,
      },
      flexcenter:{
        alignContent:'center',
        flexDirection:'row',
        alignItems:'center',
      },
      item:{
        color:'#777',
        fontSize:14
      },
      price:{
        marginRight:5,
        color:'#777',
        fontSize:14,
        fontWeight:'500'
      },
      title:{
        color:'#777',
        fontSize:14,
      },
      ids:{
        color:'#aaa',
        fontSize:14
      },
      option:{
        borderRadius:5,
        padding:2,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        paddingHorizontal:10,
        marginRight:5,
        shadowOffset: { width: 2, height: 2 },
            shadowRadius: 5,
            shadowOpacity: 0.2,
            elevation: 15,
      },
      category:{
        fontSize:14,
        color:'#479aca'
      },
  });
  