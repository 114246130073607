import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';

export const StatusBx = (status) => {
    let bg;
    switch (status) {
      case 'paid':
        bg = "green";
        break;
      case 'pending':
        bg = "red";
        break;
      default:
        bg = "#444";
    }

    return (
      <View style={{flexDirection:'row'}}>
        <View style={[styles.stat,{backgroundColor:bg}]}>
          <Text style={styles.statxt}>{status}</Text>
        </View>
      </View>
    )
  }


  export const Header = () => {
    return(
      <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
        <View style={{width:30}}/>
        {Th('Staff',3)}
        {Th('Income',2)}
        {Th('Deduction',2)}
        {Th('Amount',2)}
        <View style={{width:100}}/>
      </View>
    )
  }

  const Th = (title,size,sort) => {
    return(
        <View style={{flex:size}}>
          <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title):null}>
            <Text style={styles.item}>{title}</Text>
          </TouchableOpacity>
        </View>
  
    )
  }

  export const RowVal = (val) => {
    return <View style={styles.row}><Text style={styles.amt}>RM{val}</Text></View>
  }

  const styles = StyleSheet.create({
    stat:{
      borderRadius:5,
      marginTop:3,
      padding:3
    },
    statxt:{
      color:'#fff',
      fontSize:12
    },
    row:{
      flex:2
     },
  });