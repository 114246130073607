import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert,Button,Linking, Touchable} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Update from "./update";
import {CreditRow,DebitRow} from "./rows";
import {deleteCredit, deleteDebit} from "../../../../assets/js/firebase";
import {FormatDate} from "../../../../assets/js/format";
import {PaymentVoucher} from "../../../../assets/js/encyrpt";

export default function Table(props) {

  const {acc,tab,setList,list,next,loading,nav,removed} = props

  
  const [modified, setModified] = useState(null);
  const [option, setOption] = useState(null);
  const [sortasc, setSortAsc] = useState(true);
  const [isLoading, setIsloading] = useState(null);


  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].localeCompare(a[name])}
      setSortAsc(false) ;
      return ( a[name].localeCompare(b[name]) ) 
    })
    setList({...list, [tab]:sorted});
  }

  const Th = (title,size,sort) => {
    return(
          <TouchableOpacity style={[styles.flexcenter,{flex:size}]} onPress={()=>sort?Sortby(title,tab):null}>
            <Text style={styles.item}>{title}</Text>
            <View>{sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}</View>
          </TouchableOpacity>
    )
  }


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
            {removed && <Text style={{width:20}}/>}
            {Th('ID',1,true)}
            {Th('Description',3)}
          <View style={{width:80}}><Text style={styles.item}>Amount</Text></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != isLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }


    const ExtraDetails = (year,data) => {

        if(option !== data.id) return null

        const Link = PaymentVoucher(acc,data.id,year)
           
        const Credit = () => {
            return (
                <View style={{flexWrap:'wrap',flexDirection:'row'}}>
                    {data.claim && IconBtn('','ios-list-circle',()=>window.open('credit/claims/'+data.claim, '_blank'))}          
                    {IconBtn('','pencil',()=>setModified(data))}            
                    {data.image && IconBtn('','ios-image-outline',()=>Linking.openURL(data.image))}
                    {IconBtn('','document-text',()=>Linking.openURL(Link),'#68b7ce')}     
                    {IconBtn('','trash',()=>RemoveData(tab,data,year),'#ff572e')}           
                </View>
            )
        }

        const Debit = () => {
            return (
                <View style={{flexWrap:'wrap',flexDirection:'row'}}>
                    {IconBtn('','pencil',()=>setModified(data))}            
                    {IconBtn('delete','trash',()=>RemoveData(tab,data,year))}   
                    {IconBtn('','document-text',()=>nav.navigate('debit', {year:year,bill: data.id}),'#68b7ce')}                       
                </View>
            )
        }


        const IconBtn = (txt,icon,exc,color) => {
            if(!icon) return null
            return (
                <TouchableOpacity style={[styles.option,{backgroundColor:color?color:'#95aab0'}]} onPress={exc}>
                    <Ionicons name={icon} size={24} color="#fff" />
                    <Text style={{color:'#fff'}}>{txt}</Text>
                </TouchableOpacity>
                )
        }
      
        return (
            <View>
                {tab == 'credit'?Credit():Debit()}               
            </View>
      )
    }

    const RemoveBx = (data) => {
        if(isLoading || !removed) return null
        return (
            <TouchableOpacity value={data.id} onPress={()=>RemoveId(data.id)} style={styles.rmv}>
                <Ionicons name={'close'} size={16} color="#de8888" />
            </TouchableOpacity>
        )
    }

    async function RemoveId(id){
        setIsloading(id)
        const filteredData = list[tab].filter(item => item.id !== id);
        setList({...list, [tab]:filteredData});
        setIsloading(null)
    }
    

    const RenderItem = ({item,index}) => {

      const YearDir = FormatDate(item.date,'YYYY')


      const Selected = option == item.id
      const Bgcolor = Selected?'#fff5d4': (index % 2 == 0  ? "#FFFFFF" : "#f2f2f2")

      return(
        <TouchableOpacity onPress={()=>setOption(option == item.id?null:item.id)}>
          {RemoveLoader(item.id)}
          <View style={{flexDirection:'row',padding:5,paddingVertical:15, backgroundColor: Bgcolor }}>
              {RemoveBx(item)}
              <View style={{flex:1}}>
                <Text style={styles.ids}>#{item.id}</Text>
                <Text style={styles.date}>{FormatDate(item.date,'DD/MM/YY')}</Text>
              </View>
              <View style={{flex:3,paddingRight:5}}>
                {tab=='credit'?CreditRow(item,nav,Selected):DebitRow(item)}
                {ExtraDetails(YearDir,item)}
              </View>
              <View style={{width:80}}><Text style={styles.price}>{numberWithCommas(parseFloat(item.amount).toFixed(2))}</Text></View>
          </View>
        </TouchableOpacity>
      )
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  async function RemoveData(tab,data){
    const Title = tab == 'credit' ? data.category.name : data.name
    if (confirm('Are you sure you want to delete '+Title+' ?')) {
      setIsloading(data.id)
      setOption(null)
      const filteredData = list[tab].filter(item => item.id !== data.id);
      setList({...list, [tab]:filteredData});
      const status = tab == 'credit' ? await deleteCredit(data) : await deleteDebit(data)
      if(status){
        setIsloading(null)
      }
    } else {
      setOption(null)
    }
  }

  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    let index = dataArray.findIndex(d=> d.id === data.id)
    dataArray[index] = data  
    
    setList({...list, [tab]:dataArray})
  }



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 100 }}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <Update data={modified} clear={()=>setModified(null)} update={(val)=>UpdateData(val)} tab={tab}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    color:'#777',
    fontSize:14
  },
  price:{
    marginRight:5,
    color:'#777',
    fontSize:14,
    fontWeight:'500'
  },
  title:{
    color:'#777',
    fontSize:14,
  },
  ids:{
    color:'#aaa',
    fontSize:14
  },
  option:{
    borderRadius:5,
    padding:2,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    paddingHorizontal:10,
    marginRight:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  category:{
    fontSize:14,
    color:'#479aca'
  },
  date:{
    fontSize:12,
    fontWeight:'200',
    color:'#ccc'
  },
  rmv:{
    alignContent:'center',
    alignItems:'center',
    width:20
  }
});
