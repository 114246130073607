export function Menu() {

    const MenuArr = [
        {'name':'Accounts', icon:"wallet","menu" :[{name:'Accounts',nav:'account'},{name:'Statement',nav:'statement'},{name:'Payment Voucher',nav:'paymentvoucher'}]},
        {'name':'Products', icon:"label-multiple","menu" : [{name:'Products',nav:'product'},{name:'Costing',nav:'costing'}]},
        {'name':'Orders', icon:"receipt","menu" : [{name:'Orders',nav:'orders'},{name:'Ecommerce',nav:'order_ecommerce'},{name:'Quotation',nav:'order_quotation'},{name:'Credit Note',nav:'creditnote'}]},
        {'name':'Staff', icon:"account-group","menu" : [{name:'staff',nav:'staff'},{name:'claims',nav:'claims'},{name:'payroll',nav:'payroll'},{name:'letter',nav:'letter'}]},
        {'name':'Reports', icon:"chart-areaspline-variant","menu" : [{name:'Ledger',nav:'Ledger'},{name:'Exspenses',nav:'Exspenses'}]},
        {'name':'Transporter', icon:"truck-delivery","menu" : [{name:'Partner',nav:'transporters'},{name:'Shipment',nav:'transporter_shipment'},{name:'Payment',nav:'transporter_payments'}]},
        {'name':'Settings', icon:"cog","menu" :[{name:'Transfer',nav:'transfer'},{name:'Logout',nav:'Logout'}]}
    ]
    return MenuArr

}
