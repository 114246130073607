import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View} from 'react-native';
import {Text} from "../../../assets/component";
import Pay from './pay';
import Summarize from './summarize';


export default function PayrollFotter(props) {

  const {month,picked,set,list,update} = props


  useEffect(() => {

  }, []);
  
  




  if(!picked.length) return null


  return (
    <View style={styles.container}>
      <View style={{flex:1}}>
          <View><Text>TOTAL : {picked.length}</Text></View>
      </View>
      <Summarize details={picked} type={'KWSP'} month={month}/>
      <Summarize details={picked} type={'PERKESO'}  month={month}/>
      <Pay details={picked} set={update}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection:'row',
    padding:10,
    backgroundColor:'#bbeeff',
    position:'absolute',
    width:'100%',
    bottom:0,
    zIndex:99
  },
});
