import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import {MaterialIcons} from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import COLORS from './colors';
import TextInput from "./textinput";
import { ScrollView } from 'react-native-gesture-handler';


export default function ListDownOverlay(props) {

    const {active ,onPress,list,name,title,addnew,height} = props

    const [drop, setDrop] = useState(false);
    const [add, setAdd] = useState(false);


    useFocusEffect(
      React.useCallback(() => {
        setDrop(false)
        setAdd(false)
      }, [])
    );


    const Addnew = () => {
      if(!addnew) return null
      return (
        <View style={[styles.list,{flexDirection:'row'}]} >
            <TouchableOpacity onPress={()=>{setAdd(true),setDrop(false)}}>
                        <Text style={[styles.new]}>+ ADD NEW</Text>
            </TouchableOpacity>
        </View>
      )
    }

    const ListItem = () => {
      const Height = height?height:200
        return(
          <View style={[styles.card, styles.shadowProp,{zIndex:33,position:'absolute',maxHeight:Height,top:title?21:0}]}>
              <TouchableOpacity style={[styles.list]}  onPress={()=>{setDrop(false)}}>
                        <Text style={[styles.text]}> {active && !active.error?active:name} </Text>
              </TouchableOpacity>
              <ScrollView>
                <Addnew/>
                {list.map(key => {
                    return (
                        <TouchableOpacity key={key} style={[styles.list,{backgroundColor:key == active && COLORS.Primary}]} onPress={()=>{onPress(key),setDrop(false)}}>
                            <Text style={[styles.text,{color:key == active && '#fff'}]}> {key} </Text>
                        </TouchableOpacity>
                    ); 
                    })}
              </ScrollView>
          </View>
        )
    }

    const Title = () => {
      if(!title) return <View style={{height:5}}/>
      return (
        <View><Text style={styles.title} numberOfLines={1}>{title}</Text></View>
      )
    }

    const DropBox = () => {
      return (
        <View>                
          <Title/>
          <TouchableOpacity style={styles.listbox} onPress={()=>setDrop(!drop)}>
            <Text numberOfLines={1} style={[styles.text,{flex:1,color:active.error || !active?'#ccc':null}]}> {active && !active.error?active:name} </Text>
            <Ionicons name='chevron-down' size={18} color={'#777'} />
          </TouchableOpacity>
          {active.error && <View style={styles.errbox}><MaterialIcons name={"error-outline"} size={18} color={COLORS.Error}/><Text style={styles.error}> {active.error}</Text></View>}
        </View>
      )
    }

    const InputBox = () => {
      return (
        <View style={{flexDirection:'row'}}>
          <View style={{flex:1}}><TextInput onChangeText={onPress} value={active} placeholder={"Add New"} title={title}/></View>
          <View style={{paddingLeft:5,justifyContent:'center'}}>
              <TouchableOpacity onPress={()=>setAdd(false)}>
                <Ionicons name='close' size={18} color={COLORS.Link} />
              </TouchableOpacity>
          </View>
        </View>
      )
    }


        return (
            <View style={{zIndex:33}}>
                  {add?InputBox():<DropBox/>}
                  {drop?ListItem():null}
            </View>
        )




}

const styles = StyleSheet.create({
  text:{
    textTransform: 'capitalize',
    fontSize:16,
  },
  new:{
    textTransform: 'capitalize',
    fontSize:14,
    color:COLORS.Link
  },
  list:{
    padding:10,
    borderColor:'#eee',
    borderBottomWidth:0.5
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    borderWidth:1,
    borderColor:'#aaa',
    zIndex:33,
    marginBottom:5,
    borderRadius:5,
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  title:{
    marginBottom:5,
    color:COLORS.Text,
    paddingHorizontal:5
  },
  listbox:{
    zIndex:1,
    borderWidth:1,
    padding:9,
    borderColor:'#aaa',
    borderRadius:5,
    flexDirection:'row',
    backgroundColor:'#fff'
  },
  error:{
    fontSize:12,
  },
  errbox:{
    flexDirection:'row',
    textAlign:'center',
    alignItems:'center',
    paddingVertical:5
  }
});
