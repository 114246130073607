import React, { useState,useEffect } from 'react';
import {SafeAreaView,View,StyleSheet,Image,Text,Linking} from 'react-native';
 
import {DrawerContentScrollView,DrawerItemList,DrawerItem} from '@react-navigation/drawer';
import {getAuth,signOut} from 'firebase/auth';
import Storage from '../assets/js/storage';
import {Menu} from './menu';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaterialCommunityIcons,Ionicons } from '@expo/vector-icons';



 
export default function CustomSidebarMenu(props) {

  const [expand, setExpands] = useState(null);
  const [menuarr, setMenuArr] = useState(null);
  

    
  useEffect(() => {
    if(props){
    GetCurrent()
    setMenuArr(Menu())
    }

  }, [props]);

  function GetCurrent(){
    const ScreenName = props.state.routeNames[props.state.index]
    const MenuArr = Menu()
    let index = MenuArr.findIndex(node => node.menu.some(b => b.nav === ScreenName)); 
    if(index > -1){
      setExpands(MenuArr[index].name)
    }
  }
  

  function Logout(){
    const auth = getAuth();
      signOut(auth).then(() => {
        Storage.RemoveAll()
        console.log("signout")
      }).catch((error) => {
        console.log(error)
      });
  }
  
  
  
  
  const SubMenu = (data) => {
    const SubMenu = data.sub
    const Label = data.label
    const ScreenName = data.state.routeNames[data.state.index]

  
    const SubItem = () => {
        return  (
          <View style={{marginHorizontal:20}}>
              {SubMenu.map((key) => {
                const Selected = ScreenName == key.nav && {color:'#1e8be6',fontWeight:'400'}
              return (
                          <TouchableOpacity key={key.name} onPress={()=>NavigatePage(data,key.nav)} style={styles.subtxt}>
                            <MaterialCommunityIcons name={'chevron-right'} color={'#666'} size={20} style={[Selected]}/>
                            <Text style={[styles.txt,Selected]}>{key.name}</Text>
                          </TouchableOpacity>
                    ) 
              })}
          </View>
        )
    }

  
    return (
      <View>
        <DrawerItem
            label={data.label}
            onPress={() => setExpands(Label == expand?null:Label)}
            activeBackgroundColor='red'
            labelStyle={{color: '#666'}}
            icon={({ focused, color, size }) => {
              return <MaterialCommunityIcons name={data.icon} color={'#666'} size={20}  style={{marginRight:-20}}/>;
            }}
          />
          {expand === data.label && <SubItem/>}
      </View>
    )
  }

  function NavigatePage(data,nav){
    if(nav == 'Logout'){
      Logout()
    }else{
      data.navigation.navigate(nav)
    }
  }
  
  const Email = (data) => {
    if(!data) return null
    return (
      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        <Ionicons name={'person-circle'} color={'#aaa'} size={18} />
        <Text style={{fontSize:12,color:'#aaa',marginLeft:5}}>{data.email}</Text>
      </View>
    )
  }

  const MapMenu = () => {
    return menuarr.map((val,i)=>{
        return <SubMenu key={i} {...props} sub={val.menu} label={val.name} icon={val.icon}/>
    })
  }

  if(!menuarr) return null

  return (
    <SafeAreaView style={{flex: 1,borderRightWidth:0.5,borderColor:'#aaa'}}>
      {/*Top Large Image */}
      <View style={{padding:10}}>
      <Image source={require('../assets/images/logo_donkeez.png')}style={styles.sideMenuProfileIcon}/>
      {Email(props.user)}

      </View>
      <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
        <MapMenu/>
      </DrawerContentScrollView>

    </SafeAreaView>
  );
};
 
const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 130,
    height: 60,
    alignSelf: 'center',
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  txt:{
    color:'#666',
    fontSize:14,
    fontWeight:'300',
    textTransform: 'capitalize',
  },
  subtxt:{
    padding:5,
    borderRadius:4,
    flexDirection:'row'
  }
});
 
