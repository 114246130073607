import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Image} from 'react-native';
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {UpdateItemStatus } from "../../../assets/js/firebase";
import Update from './add'

export default function Table(props) {

  const {tab,setList,list,loading,nav} = props

  const [option, setOption] = useState(null);
  const [update, setUpdate] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [sortasc, setSortAsc] = useState(true);



  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
           <View style={{width:70}}/>
          {Th('Name',3)}
          {Th('Desc',2)}
          {Th('Type',1)}
          {Th('Created',2,true)}
         <View style={{width:20}}></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != isLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }

    const Icon = (item) => {
      if(item.type != 'service') return ProductIcon(item)
      return (
        <View style={styles.user}><Ionicons name="construct-outline" size={28} color="#ccc" /></View>
      )
    }

    const ProductIcon = (item) => {
      return (
        <Image style={styles.ProductImg} source={{uri: item.image,}}/>
      )
    }

    const RenderItem = ({item,index}) => {

      const {type} = item

      const TxtColor  = type == 'service'?'#009688':'#444'

      return(
        <View>
          {RemoveLoader(item.id)}
        <View style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row'}}>
                  <View style={styles.Icon}>{Icon(item)}</View>
                  <TouchableOpacity style={{flex:3}} onPress={()=>{type == 'stock' && nav(item)}}>
                        <Text  style={[styles.title,{color:TxtColor}]}>{item.name}</Text>
                        {Pdetail(item)}
                  </TouchableOpacity>
                  <View style={{flex:2}}><Text style={[styles.item,{color:TxtColor}]}>{item.id}</Text></View>
                  <View style={{flex:1}}><Text style={[styles.item,{color:TxtColor}]}>{type}</Text></View>
                  <View style={{flex:2}}><Text style={[styles.qty,{color:TxtColor}]}>{ReformatTime(item.createdAt)}</Text>
                  </View>
                  <View style={{width:20}}>
                    {StatusBtn(item)}
                  </View>
              </View>
              {option && option.id == item.id?Option(item):null}
        </View>
        </View>
      )
  }

  const Pdetail = (item) => {
    const {net_weight,weight,packaging_measurement} = item
    if(!weight) return null

    const Arr = [{name:'NW',value:net_weight},{name:'GW',value:weight},{name:'PM',value:packaging_measurement}]

    return (
        <View style={{flexDirection:'row',marginTop:2}}>
              {Arr.map((val,i)=>{
                  const Bwidth = Arr.length == i+1 ? 0 :1
                  return (
                      <View style={{flexDirection:'row',borderRightWidth:Bwidth,borderColor:'#aaa',paddingHorizontal:5,flexWrap:'wrap'}}>
                        <Text style={styles.details2}>{val.name}</Text>
                        <Text style={styles.details}>{val.value}</Text>
                      </View>
                  )
                })
              }
        </View>
    )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:MM A');
    return format
  }
  

  const StatusBtn = (item) => {
    return (
      <TouchableOpacity style={styles.qtyc} onPress={()=>{option && item.id == option.id ? setOption(null): setOption(item)}}>
        <Ionicons name="ellipsis-vertical" size={24} color="#777" />
      </TouchableOpacity>
    )
  }

  function UpdateStatus(data,stat){
    const status = stat?'active':'inactive'
    data['status'] = status
    const filteredData = list[tab].filter(item => item.id !== data.id);
    const dataArray = [ ...list[status]]
    dataArray.unshift(data)
    setList({...list, [tab]:filteredData,[status]:dataArray});
    UpdateItemStatus(data.id,status,'products')
    setOption(null)
  }




  const Option = (data) => {
    return (
      <View style={{flexDirection:'row',marginBottom:5,alignSelf:'flex-end'}} >
          <EditBtn data={data}/>
          <UpdateStatusBtn status={data.status} data={data}/>
      </View>
    )
  }

  const EditBtn = (props) => {
    const {data} = props
    return (
      <TouchableOpacity style={styles.option} onPress={()=> setUpdate(data)}>
          <Ionicons name="pencil" size={14} color='#4a8eb5' />
          <Text style={{color:'#4a8eb5',fontSize:14,marginLeft:2}}>Edit</Text>
      </TouchableOpacity>
    )
  }



  const UpdateStatusBtn = (props) => {
    const Status = props.status == 'inactive'?true:false
    return (
      <TouchableOpacity style={styles.option} onPress={()=> UpdateStatus(props.data,Status)}>
          <Ionicons name="remove-circle" size={16} color="#d35e5e" />
          <Text style={{color:'#4a8eb5',fontSize:14}}>{Status?'Activate':'Deactivate'}</Text>
      </TouchableOpacity>
    )
  }


  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    const result = dataArray.map((obj) => obj.id === data.id ? data : obj);
    setList({...list, [tab]:result})

    setUpdate(null)
  }
    

    return(
      <View style={styles.container}>
         <Update visible={update?true:false} updateData={update} set={()=>setUpdate(null)} update={(val)=>UpdateData(val)}/>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    color:'#aaa'
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  },
  Icon:{
    width:70,
    alignContent:'center',
    alignItems:'center'
  },
  ProductImg: {
    width: 50,
    height: 50,
  },
  details:{
    fontSize:10,
    color:'#aaa'
  },
  details2:{
    fontSize:10,
    color:'#777'
  }
});
