import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {TextInput,MultiInput,LocalCurreny} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import { TouchableOpacity } from 'react-native-gesture-handler';


export default function GenerateSalaryCol(props) {

    const {change,data,paid} = props


    const [editable, setEditable] = useState(null);

    

    useEffect(() => {
       
    }, [data]);




    const InputVal = (title,val) => {

        const inputVal = data && data[val] ? data[val]:''

        if(editable !== title){
            return (
                <View style={{marginBottom:5,paddingVertical:3,flexDirection:'row'}}>
                    <View style={{flex:1}}>
                        <Text>{title}</Text>
                    </View>
                    <TouchableOpacity style={{width:80,flexDirection:'row',alignContent:'center',alignItems:'center'}}  onPress={()=>setEditable(!paid&&title)} >
                        <Text style={{fontWeight:"500",color:COLORS.Primary,marginRight:3}}>{inputVal}</Text>
                        {!paid&&<Ionicons name='edit' size={14} color={'#ddd'} />}
                    </TouchableOpacity>
                </View>
            )
        }
        return (
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}><Text>{title}</Text></View>
                <View style={{width:80}}>
                        <TextInput
                            onblur={()=>setEditable(null)}
                            placeholder={title}
                            keyboardType="decimal-pad" maxLength={13}
                            onChange={e => change(e,val)}
                            value={inputVal}
                            />
                </View>
            </View>
        )
    }

        


    function SUMObj(obj){
        var SUM = 0
        obj.forEach((item) => {
            if(typeof data[item] === 'object' && data[item] !== null){
                data[item].forEach((item2) => {
                    SUM += parseFloat(item2.amount)?parseFloat(item2.amount):0
                })
            }else{
                SUM += parseFloat(data[item])?parseFloat(data[item]):0
            }
        });
        return LocalCurreny(SUM,true)
    }
    

    const IncomeCol = () => {
        const sub_array = 'income_others'
        return (
            <View style={[styles.ColBorder,{ flex:1,minWidth:300,zIndex:33}]}>
                <View style={{flex:1,zIndex:33}}>
                    {TitleBx('INCOME')}
                    {InputVal('Basic','basic')}
                    <MultiInput  data={data[sub_array]} add={(val)=>change(val,sub_array)} category={['Allowance (Meal)','Allowance (Mobile)','OT','Parking','Others']}/>
                </View>
                {SumboxView(['basic',sub_array])}
            </View>
        )
    }
 

    const DeductionCol = () => {
        const sub_array = 'deduction_others'
        return (
            <View style={[styles.ColBorder,{ flex:1,minWidth:200}]}>
            <View style={{flex:1}}>
                    {TitleBx('Deduction')}
                    {InputVal('Kwsp employee','kwsp2')}
                    {InputVal('Perkeso employee','socso2')}
                    {InputVal('EIS employee','eis2')}
                    {InputVal('PCB','pcb')}
                    <MultiInput data={data[sub_array]} add={(val)=>change(val,sub_array)}/>
                </View>
                {SumboxView(['kwsp2','socso2','eis2','pcb',sub_array])}
            </View>
        )
    }

    const ContributionCol = () => {
        return (
            <View style={[styles.ColBorder,{ flex:1,minWidth:200}]}>
                <View style={{flex:1}}>
                    {TitleBx('Contribution')}
                    {InputVal('Kwsp','kwsp')}
                    {InputVal('Perkeso','socso')}
                    {InputVal('EIS','eis')}
                </View>
                {SumboxView(['kwsp','socso','eis'])}
            </View>
        )
    }

    const SumboxView = (obj) => {
        return (
            <View style={{flexDirection:'row',paddingVertical:10,borderTopWidth:1,borderColor:'#aaa',borderStyle:'dotted'}}>
                <View style={{flex:1}}><Text style={{fontWeight:'500'}}>Total</Text></View>
                <View style={{width:80}}><Text style={{fontWeight:'500'}}>{SUMObj(obj)}</Text></View>
            </View>
        )

    }

    const TitleBx = (title) => {
        return (
            <View style={{paddingVertical:5,borderBottomWidth:1,flexDirection:'row',borderColor:'#ffe1cf'}}>
                <View style={{flex:1}}><Text style={{fontWeight:'500'}}>{title}</Text></View>
                <View style={{flexDirection:'row',width:80}}>
                    <Text style={{fontWeight:'400',fontSize:14}}>Amount </Text>
                    <Text style={{fontSize:10,color:'#aaa',fontWeight:'200'}}>(RM)</Text>
                </View>
            </View>
        )

    }




if(!data) return null

    
        return (

            <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                {IncomeCol()}
                {DeductionCol()}
                {ContributionCol()}
            </View>
        )

    




}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
      ColBorder:{
        borderWidth:1,
        padding:5,
        borderColor:'#eee',
        marginTop:10
      },
  });
  