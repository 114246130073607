import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet } from 'react-native';
import COLORS from './colors';
import TextInput from './textinput';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';



export default function SearchBox(props) {

    const {set,products,filters } = props

    // eg : const filters = ['id','name'] // default value name


    const [List, setList] = useState([]);
    const [search, onChangeSearch] = useState('');
    const [focus, setFocus] = useState(false);

    
    useEffect(() => {

      if(!List.length || products.length > List.length ){
        setList(products)

        if(filters && products.length){

          const result2 = products.map(e=> {
              filters.map(a=>{
                  if(!e.hasOwnProperty(a)){ e[a] = ''}
               })
        
            return e;
        });

          setList(result2)
        }
      }

    }, [products]);


    function Search(text){
      onChangeSearch(text)
      if(text){
        const newData = List.filter((item) => 
          filters ? filters.some(val => item[val].toLowerCase().includes(text.toLowerCase())) : item.name.toLowerCase().includes(text.toLowerCase()) 
        )
        if(!newData.length){
          set({error:'Not Found',search:text})
        }else{
          set(newData)
        }
      }else{
        set(List)
      }
    }

    const ObjList = () => {
      var lisss = ''
      filters.map((o,i)=> {
        if(i == 0){
          lisss += o 
        }else{
          lisss += ','+o 
        }
      })

      return lisss
    }

    if(!List) return null


    const PlaceHolder = focus ? 'Search... '+ObjList() : '🔍 Search... '+ObjList()

    return (
      <View {...props}>
                <TextInput 
                          onFocus={() =>setFocus(true) }
                          onBlur={() => setFocus(false)}
                          onChangeText={(val) => Search(val)} 
                          placeholderTextColor="#cccc" 
                          placeholder={PlaceHolder} 
                          value={search.error?'':search.toString()} 
                          style={[styles.input]}/>
      </View>

    )



}

const styles = StyleSheet.create({
  container:{
    borderWidth:1,
    borderColor:'#aaa',
    backgroundColor:'#fff',
    borderRadius:5,
    outlineColor: COLORS.Primary ,
    alignItems:'center',
    justifyContent:'center',
    paddingHorizontal:10,
  },
});
